@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "~datatables.net-buttons-bs5/css/buttons.bootstrap5.css";
@import "node_modules/font-awesome/scss/font-awesome.scss";
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body{
    font-family: 'Quicksand', sans-serif;
}

@media (max-width: 767px){
    .hamburger_icon{
        background: #333;
        height: 2px;
        padding: 0 !important;
        position: relative;  
        &:before {
            background: #333;
            height: 2px;
            content: "";
            width: 100%;
            position: absolute;
            top: -7px;
            left: 0;
            right: 0;
        }    
        &:after {
            bottom: -7px;
            background: #333;
            height: 2px;
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
        } 
    }
}